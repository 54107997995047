import { Link, PortableText } from '@the-headless-club/components'
import { ErrorPageResponse } from '@the-headless-club/models'
import {
  getErrorPage,
  getSiteConfiguration,
} from '@the-headless-club/services/sanity-service'
import { styled } from '@the-headless-club/styles'
import { GetStaticProps, NextPage } from 'next'
import { NextSeo } from 'next-seo'

const Root = styled('div', {
  $py: 'L',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$gray500_01',

  a: {
    whiteSpace: 'nowrap',
    projectFont: 'body03',
    borderRadius: '$rMax',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `fill 200ms, color 200ms, border-color 200ms, background-color 200ms`,
    paddingLeft: '$8',
    paddingRight: '$8',
    height: '$10',

    background: '$gray500',
    color: 'white',
    '&:hover': {
      borderColor: '$gray400',
      background: '$gray400',
      color: 'white',
    },
  },
})
const Content = styled('div', {
  gap: '$9',
  textAlign: 'center',
  maxWidth: '900px',
})
const Title = styled('h1', {
  projectFont: 'heading01',
  color: '$gray500',
  marginBottom: '$9',
})
const Description = styled('div', {
  marginBottom: '$9',
  color: '$gray300',
})

const Custom404: NextPage<ErrorPageResponse> = ({ pageData }) => {
  return (
    <Root>
      <NextSeo noindex={true} nofollow={true} />
      <Content>
        {pageData.title && <Title>{pageData.title}</Title>}
        <Description>
          {pageData.description && (
            <PortableText withStyles={false} value={pageData.description} />
          )}
        </Description>
        {pageData.ctaLink && (
          <Link size="medium" {...pageData.ctaLink.payload}>
            {pageData.ctaLink.title}
          </Link>
        )}
      </Content>
    </Root>
  )
}

export const getStaticProps: GetStaticProps<ErrorPageResponse> = async ({
  locale,
  preview = false,
}) => {
  const siteConfiguration = await getSiteConfiguration(locale, preview)
  const pageData = await getErrorPage(locale, preview, 'notFound')
  return {
    props: {
      siteConfiguration,
      pageData,
      showFooter: true,
    },
    revalidate: 10,
  }
}

export default Custom404
